(function() {
  // Configuration
  const config = {
    chatUrl: window.location.hostname === 'localhost' 
      ? "http://localhost:5173/chat/0dc622bb-55ad-4c6d-a16e-cd686026077b"
      : "https://imaginative-genie-7de424.netlify.app/chat/0dc622bb-55ad-4c6d-a16e-cd686026077b",
    theme: {
      primaryColor: (window.chatConfig?.theme?.primaryColor || 'rgb(233, 30, 54)'),
      onlineColor: '#22c55e'
    },
    zIndex: {
      button: 2147483646,
      widget: 2147483647
    }
  };

  // State
  let isWidgetOpen = false;

  // Styles
  const styles = {
    button: `
      all: revert !important;
      position: fixed !important; 
      bottom: 30px !important; 
      right: 30px !important; 
      width: 60px !important;
      height: 60px !important;
      color: white !important;
      border-radius: 50% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px !important;
      cursor: pointer !important;
      font-size: 24px !important;
      z-index: ${config.zIndex.button} !important;
      background: ${config.theme.primaryColor} !important;
      transition: all 0.3s ease !important;
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      outline: none !important;
      box-sizing: border-box !important;
      visibility: visible !important;
    `,
    statusIndicator: `
      position: absolute !important;
      bottom: -2px !important;
      right: -2px !important;
      width: 12px !important;
      height: 12px !important;
      background-color: ${config.theme.onlineColor} !important;
      border: 2px solid white !important;
      border-radius: 50% !important;
    `,
    widget: `
      position: fixed !important;
      bottom: 20px !important;
      right: 20px !important;
      width: 400px !important;
      height: 600px !important;
      background-color: white !important;
      border-radius: 10px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
      overflow: hidden !important;
      display: none !important;
      z-index: ${config.zIndex.widget} !important;
    `,
    iframe: `
      width: 100% !important;
      height: 100% !important;
      border: none !important;
    `,
    mobileStyles: `
      @media (max-width: 480px) {
        #chatbot-widget {
          position: fixed !important;
          top: 0 !important;
          left: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          width: 100vw !important;
          height: 100vh !important;
          max-width: 100% !important;
          max-height: 100% !important;
          margin: 0 !important;
          padding: 0 !important;
          border-radius: 0 !important;
          transform: none !important;
        }
        #chatbot-toggle {
          bottom: 30px !important;
          right: 30px !important;
        }
      }
    `
  };

  // Create Elements
  function createElement(tag, id, styles, innerHTML = '') {
    const element = document.createElement(tag);
    if (id) element.id = id;
    if (styles) {
      element.setAttribute('style', styles);
    }
    if (innerHTML) element.innerHTML = innerHTML;
    return element;
  }

  // Create Chat Interface
  function createChatInterface() {
    // Create Button
    const button = createElement('div', 'chatbot-toggle', styles.button, `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
      </svg>
    `);

    // Add Status Indicator
    const statusIndicator = createElement('div', null, styles.statusIndicator);
    button.appendChild(statusIndicator);

    // Create Widget
    const widget = createElement('div', 'chatbot-widget', styles.widget);
    
    // Create and Add Iframe
    const iframe = createElement('iframe', null, styles.iframe);
    iframe.src = config.chatUrl;
    widget.appendChild(iframe);

    // Add Mobile Styles
    const mobileStyle = createElement('style', null, null, styles.mobileStyles);
    document.head.appendChild(mobileStyle);

    return { button, widget, iframe };
  }

  // Handle Events
  function setupEventHandlers(elements) {
    const { button, widget, iframe } = elements;

    // Toggle Button Click
    button.addEventListener('click', () => {
      isWidgetOpen = true;
      widget.style.display = 'block';
      // Hide button with a slight delay to ensure smooth transition
      setTimeout(() => {
        button.style.transform = 'scale(0)';
        button.style.opacity = '0';
        button.style.visibility = 'hidden';
        button.style.pointerEvents = 'none';
      }, 50);
    });

    // Message Handler
    window.addEventListener('message', (event) => {
      switch (event.data) {
        case 'closeWidget':
          isWidgetOpen = false;
          widget.style.display = 'none';
          // Show button with a slight delay
          setTimeout(() => {
            button.style.visibility = 'visible';
            button.style.transform = 'scale(1)';
            button.style.opacity = '1';
            button.style.pointerEvents = 'auto';
          }, 50);
          break;

        case 'checkWidgetStatus':
          if (iframe.contentWindow) {
            iframe.contentWindow.postMessage(isWidgetOpen ? 'widgetOpen' : 'widgetClosed', '*');
          }
          break;
      }
    });

    // Handle page visibility changes
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && iframe.contentWindow) {
        iframe.contentWindow.postMessage('checkWidgetStatus', '*');
      }
    });
  }

  // Initialize Chat
  function initChat() {
    const elements = createChatInterface();
    setupEventHandlers(elements);
    
    document.body.appendChild(elements.button);
    document.body.appendChild(elements.widget);
  }

  // Start the chat
  initChat();
})();